import delve from 'dlv'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import useScript from 'hooks/useScript'
import fetch from 'isomorphic-unfetch'
import { useRef, useState, useEffect, useCallback } from 'react'
import { amplitudeTrack } from 'src/lib/Amplitude/analytics'

const useHubSpotForm = ({ portalId, formId, target }) => {
  const [loaded, error] = useScript({ src: '//js.hsforms.net/forms/v2.js' })
  const [formCreated, setFormCreated] = useState(false)
  const formRef = useRef(null)
  const dataRef = useRef(null)

  const updateFormMessage = useCallback(async () => {
    let attempts = 0
    const maxAttempts = 4
    const delay = 5000

    if (!formRef.current) return

    // Show waiting message
    formRef.current.innerHTML = `
      <div class="submitted-message hs-main-font-element">
        <p style="color: #33475b;">Our operator is checking if there is any available demo, please wait... (20s)</p>
      </div>`

    while (!dataRef.current && attempts < maxAttempts) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      attempts++
    }

    if (dataRef.current) {
      formRef.current.innerHTML = `
        <div class="submitted-message hs-main-font-element">
          <p style="color: #33475b;">Your demo is ready! 🎉 It may take a moment to boot up—hang tight! (5 min max)</p>
          <p style="color: #33475b;">Backend URL 👉 <a href="${delve(
            dataRef.current,
            'backend'
          )}/admin" target="_blank"> Strapi admin</a></p>
          <p style="color: #33475b;">Frontend URL 👉 <a href="${delve(
            dataRef.current,
            'frontend'
          )}" target="_blank"> Next.js app</a></p>
          <p style="color: #33475b;">🔐 Credentials:<br>Email: <b>admin@strapidemo.com</b><br>Password: <b>welcomeToStrapi123</b></p>
          <p style="color: #33475b;">You'll receive an email with more information 📧. If you have any issues, contact us at demo@strapi.io</p>
        </div>`
    } else {
      formRef.current.innerHTML = `
        <div class="submitted-message hs-main-font-element">
          <p style="color: #33475b;">We received your request! Our team is deploying your demo in the cloud ☁️.</p>
          <p style="color: #33475b;">You'll receive an email with more details 📧.</p>
        </div>`
    }
  }, [])

  const handleMessageEvent = useCallback(
    async (event) => {
      if (event.data.type !== 'hsFormCallback') return

      if (event.data.eventName === 'onFormReady') {
        const frame = document.getElementById('hs-form-iframe-0')
        if (frame) {
          const form =
            frame?.contentWindow.document.querySelector('.hbspt-form')
          if (form) {
            form.querySelectorAll('.hs-richtext').forEach((pElement) => {
              pElement.style.fontSize = '10px'
              pElement.style.lineHeight = '1.8'
            })
            formRef.current = form // Ensure formRef is set for later use
          }
        }
      }

      if (event.data.eventName === 'onFormSubmit') {
        const formData = event.data.data || []
        const payload = {
          firstname: formData.find((item) => item.name === 'firstname')?.value,
          lastname: formData.find((item) => item.name === 'lastname')?.value,
          email: formData.find((item) => item.name === 'email')?.value,
          duration: formData.find((item) => item.name === 'demo_duration')
            ?.value,
        }

        try {
          const response = await fetch('/api/demo', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          })
          dataRef.current = await response.json()
        } catch (error) {
          console.error('Error submitting form:', error)
        }
      }

      if (event.data.eventName === 'onFormSubmitted') {
        updateFormMessage()
      }
    },
    [updateFormMessage]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent)
    return () => {
      window.removeEventListener('message', handleMessageEvent)
    }
  }, [handleMessageEvent])

  useIsomorphicLayoutEffect(() => {
    if (loaded && window.hbspt && !formCreated) {
      const loadForm = async () => {
        const result = await fetch('/api/country').then((res) => res.json())

        window.hbspt.forms.create({
          portalId,
          formId,
          target,
          onFormReady: function ($form) {
            amplitudeTrack('didLoadedHubspotForm', { id: formId })

            if (result?.showDefaultConsentText === false) {
              // Remove existing .hs-richtext elements
              $form
                .querySelectorAll('.hs-richtext')
                .forEach((element) => element.remove())

              // Add the new consent message
              const consentDiv = document.createElement('div')
              consentDiv.classList.add('hs-richtext')

              const consentText = document.createElement('p')
              consentText.innerText =
                'Strapi needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, check out our Privacy Policy.'

              consentDiv.appendChild(consentText)

              const legalContainer = $form.querySelector(
                '.legal-consent-container'
              )
              if (legalContainer) {
                legalContainer.appendChild(consentDiv)
              }
            }

            $form.querySelectorAll('.hs-richtext').forEach((pElement) => {
              pElement.style.fontSize = '10px'
              pElement.style.lineHeight = '1.8'
            })

            formRef.current = $form // Ensure formRef is set for later use
          },
          onFormSubmit: function () {
            amplitudeTrack('didSubmitHubspotForm', { id: formId })
          },
          onFormSubmitted: function () {
            amplitudeTrack('didSubmittedHubspotForm', { id: formId })
          },
        })

        setFormCreated(true)
      }

      loadForm()
    }
  }, [loaded, formCreated])

  return { loaded, formCreated, error }
}

export default useHubSpotForm
