import React from 'react'
import useHubSpotForm from 'hooks/useHubSpotForm'
import classnames from 'classnames/bind'
import LabelTitleText from 'components/shared/LabelTitleText'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const ContactForm = ({ className, formId, portalId, formIntro }) => {

  useHubSpotForm({
    portalId,
    formId,
    target: `.form-${formId}`,
  })

  return (
    <>
      <div id="contact" className={cx(css.ContactForm, className)}>
        {formIntro && (
          <LabelTitleText
            className={css.formIntro}
            titleProps={{
              size: 'small',
            }}
            center
            {...formIntro}
          />
        )}

        <div className={`form-${formId}`} />
      </div>
    </>
  )
}

export default ContactForm
