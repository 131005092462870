import Link from 'components/shared/Link'
import { Text } from 'src/components/typo'
import css from './styles.module.scss'
import classnames from 'classnames/bind'

const NewsWithLink = ({
  link,
  text,
  badgeText,
  newsWithLinkCustomTextColor,
  isStrapi5Hero,
}) => {
  const cx = classnames.bind(css)
  return (
    <div
      className={cx([
        css.wrapper,
        isStrapi5Hero ? css.strapi5Wrapper : css.marginBottom,
      ])}
    >
      <div className={css.contentWrapper}>
        <div
          className={cx([
            css.tag,
            isStrapi5Hero ? css.tagPink : css.tagLavender,
          ])}
        >
          {badgeText}
        </div>

        <div className={css.textWrapper}>
          <Text
            theme={
              newsWithLinkCustomTextColor ? newsWithLinkCustomTextColor : 'gray'
            }
            className={cx({
              [css.text]: isStrapi5Hero,
            })}
          >
            {text}
          </Text>
          <Link
            {...link}
            className={cx([
              css.link,
              isStrapi5Hero ? css.linkPink : css.linkLavender,
            ])}
          />
        </div>
      </div>
    </div>
  )
}

export default NewsWithLink
